@import '../../../assets/styles/variables';
@import '../../../assets/styles/base';
.messageContainer {
  padding: 12px 32px;
  min-width: 229px;
  width: auto;
  max-width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  border-radius: 16px;
  background: $white;
}

.noDataMessage {
  margin: 0 0 0 20px;
}
