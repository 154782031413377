@import '../../../assets/styles/variables';
@import '../../../assets/styles/base';
.hightlightWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  @media only screen and (max-width: 768px) {
    padding-left: 40px;
    gap: 20px;
  }
}