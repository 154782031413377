@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/base';
.tooltip {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 6.80597px;
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.1);
  padding: 8px 12px;
  gap: 8px;
  width: auto;
  height: 40px;

  @media only screen and (max-width: 768px) {
    height: 24px;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    display: flex;
    height: 24px;
    margin: 0;
    white-space: nowrap;

    @media only screen and (max-width: 768px) {
      font-size: 10px;
    }
  }
}

.leftTooltip {
  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-left-color: #fff;
    border-right: 0;
    margin-top: -10px;
    margin-right: -9px;
  }

  @media only screen and (max-width: 768px) {
    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      width: 0;
      height: 0;
      border: 6px solid transparent;
      border-left-color: #fff;
      border-right: 0;
      margin-top: -6px;
      margin-right: -5px;
    }
  }
}

.rightTooltip {
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-right-color: #fff;
    border-left: 0;
    margin-top: -10px;
    margin-left: -9px;
  }

  @media only screen and (max-width: 768px) {
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      width: 0;
      height: 0;
      border: 6px solid transparent;
      border-right-color: #fff;
      border-left: 0;
      margin-top: -6px;
      margin-left: -5px;
    }
  }
}

.box {
  position: relative;
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 2px;

  @media only screen and (max-width: 768px) {
    width: 11.28px;
    height: 11.28px;
  }

  &.green {
    background: #c1c3d7;
  }
  &.blue {
    background: #5d6293;
  }
}
