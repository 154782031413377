@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/base';
.modalContainer {
  text-align: center;
  padding: 44px 30px;
  border-radius: 24px;
}

.modalBody {
  padding: 0;
}

.lockIcon {
  margin-bottom: 16px;
}

.info {
  width: 70%;
  margin: auto;
}

.actions {
  margin-top: 22px;
  display: flex;
  justify-content: space-evenly;
}
