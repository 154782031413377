@import '../../../../../../../assets/styles/variables';
@import '../../../../../../../assets/styles/base';
.popoverContentWrapper {
  padding: 32px;

  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 360px;
  @media only screen and (max-width: 768px) {
    background-color: $white;
    width: 100%;
    border-radius: 24px 24px 0px 0px;
  }
}

.configureButton {
  width: 154px;
  height: 48px;
  min-width: 0;
}

.resetButton {
  border: 1px solid $grey;
  background-color: $ghost-white;
  color: #5d6293;
}

.outlinedSelect {
  box-sizing: border-box;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
    padding-left: 16px;
  }
}

.selectMenu {
  color: #5d6293;
  box-shadow: none;
  max-height: 426px;
  width: 296px;
  border: 1px solid #f5f5fc;
  border-radius: 8px;

  & li {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 14px;

    & > div > div {
      text-overflow: ellipsis;
      overflow: hidden;
      text-wrap: nowrap;
    }

    & span {
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }
}

.selectLabel {
  font-size: 12px;
  font-weight: 400;
  color: $dark-blue;
  margin-bottom: 0;
  & > li {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.selectCheckboxLabel {
  font-size: 12px;
  font-weight: 400;
  color: $dark-blue;
  margin-bottom: 0;
  line-height: 16px;
}

.toggleButton {
  width: 80px;
  height: 40px;
  font-size: 14px;
}

.onlyThis {
  color: #5d6293;
  font-size: 14px;
  font-weight: 400;
  // line-height: 20px;
  margin-right: 24px;

  &:hover {
    text-decoration: underline;
  }
}

.onlyThisHiddenItem {
  & p {
    display: none;
  }

  &:hover {
    & p {
      display: block;
    }
  }
}
