@import '../../../assets/styles/variables';
@import '../../../assets/styles/base';
.desktopPageContainer {
  height: 100vh;
  background: $ghost-white;
  display: flex;
  justify-content: center;
}

.mobilePageContainer {
  height: 100vh;
  background: $white;
  display: flex;
  justify-content: center;
}

.contentWrapper,
.mobileContentWrapper {
  padding: 32px 72px;
  height: calc(100% - 96px);
  width: 100%;
  position: absolute;
  top: 96px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  overflow-y: auto;
}

.mobileContentWrapper {
  top: 54px;
  padding: 0;
  left: 0;
  bottom: 48px;
  width: 100%;
  font-size: 16px;
  height: 100%;
}
