@import '../../../assets/styles/variables';
@import '../../../assets/styles/base';
.tooltip {
  font-size: 14px;
  color: $spanish-grey;
  text-align: center;
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: 0px 8px 16px -2px $pantone;
  background: $white;
}

.tooltipArrow {
  color: $white;
}
