@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/base';
.occupancyRateContainer {
  height: 100%;
  margin-top: 24px;
  background: $white;
  border-radius: 24px;
}

.occupancyRateHeader {
  display: flex;
  flex-direction: row;

  align-items: center;
  text-wrap: nowrap;
  gap: 8px;
}

.occupancyRate {
  display: flex;
  flex-direction: column;

  p {
    color: $light-blue;
    margin-top: 0px;
  }

  @media only screen and (max-width: 768px) {
    align-items: center;
  }
}

.dropdown {
  @media only screen and (max-width: 768px) {
    width: 130px;
    height: 32px;
  }
}

.headerTooltip {
  padding: 0;
}
