@import '../../../assets/styles/variables';
@import '../../../assets/styles/base';
.container {
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    font-size: 16px;
    line-height: 20px;
    color: $dark-grey;
  }
}

.imageContainer {
  width: 24px;
  height: 24px;
}

.reversed {
  flex-direction: row-reverse;
}
