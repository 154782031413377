@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/base';
.ownerOccupancyWrapper {
  width: 100%;
  background: $white;
  border-radius: 24px;
  padding: 32px;

  @media only screen and (max-width: 768px) {
    padding: 16px;
  }
}

.table {
  width: 100%;

  & th,
  & td {
    border: 0;
    text-align: center;
    width: 96px;
  }
}

.headerCell {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #02094f;
  padding: 0;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
}

.yearCell {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #02094f;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
  box-sizing: border-box;
}

.monthCell {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  border-radius: 16px;
  padding: 16px;
  color: #5d6293;
  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
  box-sizing: border-box;
}
