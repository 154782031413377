@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/base';
.filtersContrainerWrapper {
  width: 100%;
  background: $white;
  border-radius: 24px;
  padding: 32px;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    padding: 16px;
  }
}

.liveProperties {
  color: $dark-blue;
}

.livePropertiesCount {
  color: $white;
  border-radius: 6px;
  padding: 1.5px 8px;
  background-color: $green-house;
  font-weight: 500;
  font-size: 18px;
}

.headerText {
  margin-bottom: 8px;
  margin-top: 8px;
  font-weight: 500;
  font-size: 28px;

  @media only screen and (max-width: 768px) {
    font-size: 24px;
  }
}

.downloadIcon {
  border: 1px solid $grey;
  border-radius: 16px;
  padding: 11px 13px;
  height: min-content;
}

.filterLabel {
  font-size: 18px;
  color: #5d6293;
}

.outlinedRevenueButton {
  padding: 11px 20px;
  border-color: $grey;
  min-width: 0px;
  height: min-content;

  @media only screen and (max-width: 768px) {
    width: min-content;
  }
}

.filtersButton {
  border-color: $grey;
}

.responsiveButton {
  min-width: 164px;
  height: 48px;

  @media only screen and (max-width: 768px) {
    padding: 5px 14.5px;
    width: min-content;
    min-width: 0;
  }
}
