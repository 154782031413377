@import '../../../assets/styles/variables';
@import '../../../assets/styles/base';
.menuItem {
  margin: 15px 0;
}

.menuItemLabel {
  color: $grey;
}

.activeItemLabel {
  color: $light-blue;
}

.activeMenuButton {
  border-left: 5px solid $light-blue;
}

.menuItemIcon {
  margin: 10px;
}

.activeMenuIcon {
  margin: 10px 10px 10px 5px;

  & svg {
    & path {
      fill: $light-blue;
    }

    & rect {
      &~path {
        fill: $white;
      }
      fill: $light-blue;
    }
  }
}
