$white: #ffffff;
$ghost-white: #f5f5fc;
$grey: #b4b7cf;
$light-grey: #d3d4de;
$dark-grey: #9798a5;
$spanish-grey: #93949e;
$pantone: #1b212c1f;
$light-green: #d9f8f0;
$green: #008c74;
$blue-grey: #5d6293;
$light-blue: #5d6293;
$dark-blue: #02094f;
$light-red: #ffe1e7;
$red: #ff385c;
$yellow: #edc255;
$green-house: #00b48d;
