@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/base';
.occupancyChartContainer {
  padding: 32px;
  height: 430px;
  background: $white;
  width: 100%;
  border-radius: 24px;
  @media only screen and (max-width: 768px) {
    padding: 16px;
    padding-bottom: 32px;
  }
}

.occupancyChartHeader {
  margin: 0 0 30px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  @media only screen and (max-width: 768px) {
    margin: 0 0 24px 0;
  }
}

.occupancyLegendContainer {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.tooltip {
  position: absolute;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 6.80597px;
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.1);
  padding: 0px 12px 12px 12px;
  gap: 8px;
  font-size: 14px;
  width: max-content;
  & > p {
    color: $grey;
  }
}

.tooltipLegends {
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0;
  padding: 0;
  color: $grey;
}

.tooltipTotal {
  font-size: 18px;
  font-weight: 500;
  color: $dark-blue !important;
  margin: 0;
  padding: 0;
}

.box {
  position: relative;
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 2px;

  @media only screen and (max-width: 768px) {
    width: 11.28px;
    height: 11.28px;
  }

  &.green {
    background: $green;
  }
  &.yellow {
    background: $yellow;
    width: 16px; /* Ширина желтой полоски */
    height: 4px; /* Высота желтой полоски */
  }
  &.red {
    background: #e76d83;
  }
  &.purple {
    background: #5d6293;
  }
  &.grey {
    background: #e0e1eb;
  }
}
