@import '../../../assets/styles/variables';
@import '../../../assets/styles/base';
.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.actionsContainer {
  padding: 0 50px 0 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logo {
  max-height: 60px;
  max-width: 143px;
  width: 100%;
  margin-left: 40px;
}

.menuIcon {
  height: 18px;
}

.userData {
  color: $light-blue;
  margin: 0 20px 0 25px;
}

.tooltip {
  font-size: 14px;
  color: $spanish-grey;
  text-align: center;
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: 0px 8px 16px -2px $pantone;
  background: $white;
}

.tooltipArrow {
  color: $white;
}
