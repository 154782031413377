@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/base';
.occupancyRateContainer {
  padding: 30px;
  width: 100%;
  background: $white;
  border-radius: 24px;
  @media only screen and (max-width: 768px) {
    padding: 16px;
    padding-top: 32px;
  }
  text-wrap: nowrap;
}

.occupancyRateHeader {
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.headerWithTooltip {
  display: flex;
  gap: 8px;
  align-items: center;
}

.header {
  font-weight: 500;
  font-size: 24px;

  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
}

.legend {
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    padding-top: 6px;
    justify-content: center;
  }
}

.pieContainer {
  width: 175px;
  position: relative;
  margin-top: 24px;
  align-items: center;
}

.total {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dropdown {
  width: 164px;
  @media only screen and (max-width: 768px) {
    width: 130px;
    height: 32px;
  }
}

.toast {
  position: absolute;
  top: 38%;
  left: 20%;
}

.mpiWrapper {
  background: $ghost-white;
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 24px;
  border-radius: 16px;
  text-wrap: nowrap;
  width: 164px;

  @media only screen and (max-width: 768px) {
    flex-direction: row;
    height: auto;
    padding: 24px 0px;
    gap: 24px;
    width: 100%;
  }
}
