body {
  line-height: 24px;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5F5FC;
  overflow-x: hidden;
  overflow-y: auto;
}

* {
  box-sizing: border-box;
}

.recharts-layer.recharts-cartesian-axis-tick text {
  fill: #9798A5;
}

.react-datepicker__tab-loop__start, .react-datepicker__tab-loop__end {
  display: none;
  visibility: hidden;
}
