@import '../../../../../../../assets/styles/variables';
@import '../../../../../../../assets/styles/base';
.popoverContentWrapper {
  padding: 32px;

  display: flex;
  flex-direction: column;
  gap: 12px;
  @media only screen and (max-width: 768px) {
    background-color: $white;
    width: 100%;
    border-radius: 24px 24px 0px 0px;
  }
}

.configureButton {
  width: 154px;
  height: 48px;
  min-width: 0;
}

.resetButton {
  border: 1px solid $grey;
  background-color: $ghost-white;
  color: #5d6293;
}

.checkboxLine {
  padding-bottom: 8px;
  font-size: 14px;
}
