@import '../../../assets/styles/variables';
@import '../../../assets/styles/base';
.notFoundPageWrapper {
  align-items: center;
  justify-content: center;
}

.notFoundPageContainer {
  display: flex;
  flex-direction: column;
  padding: 32px;
  width: 621px;
  min-height: 374px;
  height: auto;
  background: $white;
  border-radius: 24px;
  overflow-y: auto;
  justify-content: flex-start;
  align-items: center;

  h4 {
    display: flex;
    margin-bottom: 8px;
    line-height: 30px;
  }

  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    margin-top: 0;
    margin-bottom: 24px;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 16px;
  }
}

.notFoundImage {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 12px 0 20px 0;
  margin-bottom: 12px;
}