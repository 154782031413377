h2 {
  font-size: 24px;
  font-weight: 400;
  color: $dark-blue;
  margin-top: 0;
}

h3 {
  font-size: 18px;
  font-weight: 500;
  color: $light-blue;
  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
}

h4 {
  font-size: 18px;
  font-weight: 500;
  color: $dark-blue;
  margin: 0;
  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
}

p {
  font-size: 16px;
  font-weight: 400;
  color: $dark-grey;
  margin-bottom: 0;
}