@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/base';
.header {
  margin-bottom: 20px;
  text-wrap: nowrap;
  font-weight: 500;
  font-size: 24px;

  @media only screen and (max-width: 768px) {
    font-size: 18px;
    display: flex;
    justify-content: left;
    align-items: center;
  }
}

.tableContainer {
  padding: 32px;
  width: auto;
  height: 100%;
  background: $white;
  border-radius: 24px;

  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 16px;
    height: 300px;
  }
}

.tableWrapper {
  max-height: 251px;
  overflow: auto;
}

.table {
  width: 100%;

  @media only screen and (max-width: 768px) {
    width: 100%;
    overflow-y: auto;
  }

}

.tableHead {
  & th {
    font-size: 16px;
    color: $light-blue;

    &:first-of-type {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-of-type {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}

.tableBody {
  & > tr > td {
    font-size: 14px;
  }
  & tr:first-of-type {
    & td {
      padding-top: 20px;
    }
  }
}

.tableRow {
  & td {
    font-size: 16px;
    color: $dark-grey;
    padding: 10px;
  }
}

@media only screen and (max-width: 1200px) {
  .tableContainer {
    max-width: 100%;
  }

  .table {
    width: 100%;
  }
}

@media only screen and (max-width: 400px) {
  .tableHead {
    & th {
      padding: 6px 6px;
    }
  }
}

.tableTotal {
  & > td {
    border: 0 !important;

    & > h4 {
      font-size: 18px !important;
    }
  }
}

// tool tip cass added on 25-12-2024
.tooltip {
  position: relative;
  display: inline-block;
  z-index: 4;
  top: 1px;
  left: 7px;
  }
  
  .tooltip .tooltiptext {
  visibility: hidden;
  width: 302px;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  transform: translateX(-50%);
  background: #ffffff;
  white-space: break-spaces;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 10px;
  line-height: 23px;
  font-weight: 500;
  font-size: 15px;
  color: #88888fd9;
  font-family: 'Poppins', sans-serif;
  z-index: 5;
  }
  
  .tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #ffffff transparent transparent transparent;
  }
  
  .tooltip:hover .tooltiptext {
  visibility: visible;
  }

.stickyTotal {
  position: sticky;
  bottom: 0;
  background-color: #fff;
}

// .tableWrapper::-webkit-scrollbar {
// width: 0px;
// }
.RevenueTable_tableRow__KM10C td {
  padding: 4px 10px;
}
.tableWrapper{
  max-height: 320px;
}
.tableTotal td{
  padding: 15px 10px 0 15px;
}